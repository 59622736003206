import React from "react"
import "./Footer.scss"

export const Footer: React.FC = () => (
  <div className="BlogFooter">
    <a href="https://elevenback.co.jp" target="_blank">
      <span>&copy; 2019 ElevenBack LLC.</span>
    </a>
    <a href="https://blog.candy.ac/rss.xml" target="_blank">
      <span>RSS</span>
    </a>
  </div>
)
