import * as React from "react"
import { Link } from "gatsby"

import "./Header.scss"
// ______________________________________________________
//
const Component: React.FC = () => (
  <div className="WrapHeader">
    <Link className="HeaderString" to="/">
      <img src={require("../../../assets/logo.svg")} width="142" />
    </Link>
  </div>
)
// ______________________________________________________
//
export default Component
